.body {
    width: 100%;
    height: 100%;
  }
  .dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(45deg, white, #f4f1f4); */
  }
  .searchbar {
    width: 100%;
    height: 80%;
    background: url(../../assets/entvin.png) no-repeat right, linear-gradient(to right, #e4edff, #ffe5f7, #dceaff);
    padding: 3rem 0 2.5rem;
  }
  
  .label {
    color: #0e1728;
    font-size: 1.75rem;
    font-weight: 500 !important;
    text-align: center;
  }
  .searchBox {
    width: 30%;
    margin: 1rem auto 0 auto;
    box-shadow: 0px 8px 20px #0000000f;
  }
  .autoComplete {
    width: 100%;
  }
  .searchBox .inputBox {
    width: 100%;
    height: 4rem;
    border-radius: 0.5rem;
    padding-left: 1.5rem;
  }
  .searchBox .inputBox input::placeholder {
    color: #667085;
    font-size: 1rem;
    font-weight: 350;
  }
  .navigation {
    margin: 0.5rem 5rem;
  }
  .navigation .ant-tabs-tab {
    font-size: 0.9rem;
    color: #71819f;
  }
  .navigation .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .navigation .ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 0.9rem;
    color: #0e1728;
  }
  .navigation .ant-tabs-ink-bar {
    background-color: #0e1728; /* Color of the underline */
  }
  .entityLocations {
    margin: 0.5rem 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sectionNameWrapper {
    font-size: 1.2rem;
    color: #0e1728;
  }
  
  .custom-spinner {
    font-size: 170px !important; /* Increase the font size to make the spinner larger */
    width: 170px !important; /* Set the width to make the spinner larger */
    height: 170px!important;
    margin: 0 auto; /* Center horizontally */
    margin-top: 60px; /* Add top margin for vertical centering */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }