.body {
  width: 100%;
  height: 100%;
}
.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(45deg, white, #f4f1f4); */
}
.searchbar {
  width: 100%;
  height: 352px;
  background: url(../../assets/entvin.png) no-repeat right, linear-gradient(to right, #e4edff, #ffe5f7, #dceaff);
  padding: 7.6rem 0 2.5rem;
}

.label {
  color: #0e1728;
  font-size: 1.75rem;
  font-weight: 500 !important;
  text-align: center;
}
.searchBox {
  width: 30%;
  margin: 1rem auto 0 auto;
  box-shadow: 0px 8px 20px #0000000f;
}
.autoComplete {
  width: 100%;
}
.searchBox .inputBox {
  width: 100%;
  height: 4rem;
  border-radius: 0.5rem;
  padding-left: 1.5rem;
}
.searchBox .inputBox input::placeholder {
  color: #667085;
  font-size: 1rem;
  font-weight: 350;
}



.custom-spinner {
  font-size: 170px !important; /* Increase the font size to make the spinner larger */
  width: 170px !important; /* Set the width to make the spinner larger */
  height: 170px!important;
  margin: 0 auto; /* Center horizontally */
  margin-top: 160px; /* Add top margin for vertical centering */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.searchTagline{
  color: #71819F;
  font-family: "Inter-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  text-align: center;
  margin-top: 48px;
}