
.companyPageTile{
    width: 93%;
    margin: 0 auto;
    padding: 20px;
    height: max-content;
}
.ant-drawer-body{
    padding: 0px !important;
}
.ant-drawer-header{
    border-bottom: none !important;
    padding-top: 20px;
}
.companyPageInfo{
    height:60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Distribute items evenly with space in between */

}
.companyPageHeader{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 44px;
    font-weight: 500;
    line-height: 60px;
    max-width: 50%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.companyCountryandSocials{
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Distribute items evenly with space in between */
}
.CompanyCountrySection{
    height: 40px;
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.socials{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    width: fit-content;
    /* width: 144px; */
}
.socialDiv{
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    margin-right: 12px;
}
.certificatesRow{
    height: 46px;
    padding-top: 12px;
    padding-bottom: 12px;
}


.companyAddressRow{
    display: flex;
    flex-direction: column;
    height: 48px;
    justify-content: space-evenly;
}
.manufacturingAddress{
    color: #71819F;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.addressRow{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.companyInfoCards{
    display: flex;
    width: 100%;
    flex-direction: row;

}
.individualInfoCard{
    height: 116px;
    width: 26%;
    margin-right: 20px;
}

.individualinfoCardLabel {
    color: #71819f;
    margin-bottom: 0.3rem;
  }
.individualinfoCardValue {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}