.tableWrapper{
    height: auto;
    min-height: 401px;
    margin: 0.5rem 5rem;
    /* display: flex; */
}
.searchCompanyBox{
    width: 22%;
    height: 44px;
    left: 0;
    box-shadow: 0px 8px 20px #0000000f;
    margin-bottom: 2rem;
    margin-top: 2rem;

}
.autoComplete {
    width: 100%;
}
.searchCompanyBox .inputBox {
    width: 100%;
    height: 2.75rem;
    border-radius: 0.5rem;
    padding-left: 1.5rem;
}
.searchCompanyBox .inputBox input::placeholder {
    color: #667085;
    font-size: 1rem;
    font-weight: 350;
}
.entityTable{
    height: 100%;
}
/* CustomPagination.css */
.ant-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center align items vertically */
  }
  
  /* Create space between page numbers and buttons */
  .ant-pagination-item {
    margin: 0 4px; /* Adjust the margin as needed */
  }
  
  /* Adjust margin for "Previous" and "Next" buttons */
  .ant-pagination-prev {
    margin-right: auto; /* Push "Previous" to the far left */
  }
  
  .ant-pagination-next {
    margin-left: auto; /* Push "Next" to the far right */
  }
.buyerClass{
    background-color: #F9F5FF;
    color: #6840c6;
    border-radius: 16px;
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 22px;
  }
.sellerClass{
    background-color: #FDF2FA;
    color: #c01573;
    border-radius: 16px;
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 22px;

  }
.buyer-sellerClass{
    background-color: #F2F4F7;
    color: #0e1728;
    border-radius: 16px;
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 22px;


}
.companyNameTable{
    color: #0e1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 18px;
    font-weight: 600;
    height: 52px;
    padding: 0px 24px 0px 24px;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
}
.chemicalTable1{
    color: #667085;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 400;
    height: 26px;
    /* padding: 0px 24px 0px 24px; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    /* justify-content: space-between; */

}
.productDescription{
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.countrySection{
    display: flex;

}
.countryTable{
    padding-left: 12px;
    color: #667085;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 400;
}
.registrationsTable{
    background-color: #F2F4F7;
    color: #344054;
    border-radius: 16px;
    font-family: "Inter-Medium", Helvetica;
    font-size: 12px;
    font-weight: 500;
    height: 22px;

}
.tableButtons{
    background-color: white;
    width: 114px;
    height: 36px;
    border-radius: 8px;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;

}

.viewDetailsTable{
    background-color: white;
    color: #6941C6;
    font-family: "Inter-Medium", Helvetica;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

}
.ant-drawer-content {
    border-radius: 12px !important;
    max-height: 900px !important;
    overflow-y: scroll;
}

.fullPage{
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerTile{
    border-radius: 8px;
    width: 91%;
    margin: 0 auto;
    border: 0.5px solid #F2F2F2;
    padding: 20px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    height: max-content;
}
.ant-drawer-body{
    padding: 0px !important;
}
.ant-drawer-header{
    border-bottom: none !important;
    padding-top: 20px;
}
.chemicalRow{
    height:60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Distribute items evenly with space in between */

}
.chemicalNameRow{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 44px;
    font-weight: 500;
    line-height: 60px;
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chemicalRowDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Distribute items evenly with space in between */
}
.certificatesRow{
    height: 46px;
    padding-top: 12px;
    padding-bottom: 12px;
}
.companyInfoRow{
    padding-top: 12px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
}
.companyNameRow{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
}
.companyAddressRow{
    display: flex;
    flex-direction: column;
    height: 48px;
    justify-content: space-evenly;
}
.manufacturingAddress{
    color: #71819F;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.addressRow{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}
.capacityTag{
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    gap: 10px;
    background-color: #41C7EB33;
   display: flex;
    
}
.capacityTagText{
    color: #0E1728;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    height: 18px;
    margin: auto;
    margin-left: 0;
}
.productsDiv{
    width: 91%;
    height: max-content;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 2rem;
}
.companyContacsDiv{
    width: 100%;
    height: 600px;
    margin: 0 auto;
    margin-top: 36px;
    margin-bottom: 36px;
}

.fitwithinSpan{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block
}
.entityListTable .ant-table{
    height: 580px;
}
.tableTitle{
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
}
.searchCompanyBox1{
    width: 320px;
    height: 44px;
    left: 0;
    box-shadow: 0px 8px 20px #0000000f;
}

.searchCompanyBox1 .inputBox {
    width: 100%;
    height: 2.75rem;
    border-radius: 0.5rem;
    padding-left: 1.5rem;
}
.searchCompanyBox1 .inputBox input::placeholder {
    color: #667085;
    font-size: 1rem;
    font-weight: 350;
}
