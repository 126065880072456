/* styles.css */
.custom-marker {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color */
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    /* Outer shadow (bigger) */

    /* Inner shadow (smaller) */
    /* box-shadow: 0 0 10px  #C11574,(0, 0, 0, 0.5), 0 0 20px  #C11574,(0, 0, 0, 0.5); */

}
  