.filters {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.filterText {
  color: #0e1728 !important;
  font-size: 1rem;
}
.buttons {
  border-radius: 1.5rem;
  height: 2.8rem;
  margin-left: 1rem;
  width: 6rem;
  border: none;
  box-shadow: 0 0 0.1rem 0.1rem rgba(16, 24, 40, 0.05);

}
.filters .ant-select-selector {
  width: 12rem;
  height: 3rem;
  border-radius: 0.5rem !important;
  margin-left: 1rem;
  border: none !important;
  box-shadow: 0 0 0.1rem 0.1rem rgba(16, 24, 40, 0.05);
}
.filters .ant-select-selection-placeholder {
  color: #667085;
}
