.tag {
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  max-width: 21%;
  margin-right: 0.8rem;
  height: 2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
