.tag1 {
    /* padding: 0.2rem 1rem; */
    border-radius: 2rem;
    width: 210px;
    /* margin-right: 0.8rem; */
    height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: Helvetica Neue;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0 auto;
}


.node-label {
    word-wrap: break-word;
    height: 37px;
    border: 1px solid;
    width: 240px;
    display: flex;
    overflow: hidden;
    padding: 6px 16px 12px 6px;
    border-radius: 30px;
    gap: 10px;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 0px 0px 1px #0E1728;
    text-align: center;
    display: flex;

}


.ROSgraph{
    /* width: 100%; */
    /* height: 600px; */
    margin: 5rem 5rem;
}
.rosHeadline{
    color: #0E1728;
    font-family: "Inter-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    height: 28px;
}
.rosBorderBox{
    background-color: #EAECF0;
    height: 0px;
    border: 1px solid #EAECF0;
    margin-top: 20px;
    margin-bottom: 20px;    
}
.reactFlowGraph{
    border: 1px solid #EAECF0;
    
}
.ManufacturingProcess{
    display: flex;
}
.ManufacturingImage{
    margin-right: 12px;
}
.ManufacturingTitle{
    height: 20px;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #71819F;
    margin-bottom: 8px;
}
.ManufacturingText{
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1728;
}
.rosContent{
    border: 0.5px solid #F2F2F2;
    box-shadow: 0px 1px 2px 0px #1018280D;   
    padding: 44px 28px 36px 28px;
    border-radius: 8px;
    gap: 32px;
}
.reactFlowGraph{
    height: 600px;
    margin-bottom: 32px;
}
.manufacturing-carousel{
    display: flex;
    justify-content: center;
    align-items: center;
}

.manufacturingImages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slider-image{
    max-height: 500px;
    max-width: 100%; 
    object-fit: contain;

}