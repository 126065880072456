.mapSection {
  margin: 0.5rem 5rem;
  display: flex;
}
.mapWrapper {
  width: 75%;
  border-radius: 1rem;
  margin-right: 2rem;
}
.sectionName {
  font-size: 1.4rem;
}
.entitySummary{
  padding: 20px;
  padding-top: 40px;
}
.entityCount{
  font-family: "Helvetica Neue-Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 32px;
  /* position: fixed; */
  top: 0;
}

.div-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9px;
  position: relative;
}


.div-5 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.ellipse {
  background-color: #6840c6;
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

 .text {
  color: #6840c6;
  font-family: "Helvetica Neue-Medium", Helvetica;
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

 .ellipse-2 {
  background-color: #c01573;
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

 .text-2 {
  color: #c01573;
  font-family: "Helvetica Neue-Medium", Helvetica;
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.ellipse-3 {
  background-color: #0e1728;
  border-radius: 4px;
  height: 8px;
  position: relative;
  width: 8px;
}

.text-3 {
  color: #0e1728;
  font-family: "Helvetica Neue-Medium", Helvetica;
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.entityTags{
  padding-top: 300px;
}
.tableTitle{
  height: 36px;
  font-family: Helvetica Neue;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.07em;
  text-align: left;
  color: #0E1728;
  margin-bottom: 12px;
}