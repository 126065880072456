.chemicalInfoSection {
  display: flex;
  height: 20rem;
  margin: 2.5rem 5rem;
}
.ant-card {
  border-radius: 0.5rem;
  border: 0.2rem solid white;
  box-shadow: 0 0 1rem rgba(16, 24, 40, 0.05);
}
.molecularStructure {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chemicalInfo {
  margin-left: 2rem;
  display: flex;
  width: 70%;
  flex-direction: column;
}
.chemicalLabel {
  display: flex;
  align-items: baseline;
}
.chemicalName {
  font-size: 2.4rem;
  margin-right: 0.3rem;
  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

}
.chemicalSubName {
  font-size: 1.2rem;
  font-weight: 400;
}
.tags {
  display: flex;
  margin-top: 0.3rem;
}
.chemicalInfoCards {
  margin-top: 1.2rem;
  display: flex;
  justify-content: space-between;
}
.infoCard {
  width: 32%;
  height: 12.8rem;
}
.infoCardLabel {
  color: #71819f;
  margin: 1.2rem 0 0.5rem;
}
.infoCardValue {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 0.3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.infoCardLink {
  margin-bottom: 0.8rem;
  color: #6941c6;
}
.chemicalLabel1{
  margin-left: 5rem;
  display: flex;
  align-items: baseline;
  margin-top: 1rem;
}