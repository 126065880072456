.authPage{
    width: 100%;
    height: 100vh;
}
.authPageheader{
    height: 80px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    text-align: center;
}
.authPageBody{
    background: linear-gradient(to right, rgba(228, 237, 255, 0.7), rgba(255, 229, 247, 0.7), rgba(220, 234, 255, 0.7));
    /* mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), transparent); */

    background-size: 100% 352px ;
    background-repeat: no-repeat;
    height: calc(100vh - 80px) !important;
    padding-top: 44px;
    display: flex;
    flex-direction: column;
}
.authImgIcon{
    margin: 0 auto;
    margin-bottom: 24px;
}
.authSwitchTab{
    display: flex;
    width: 316px;
    height: 48px;
    background-color:#F9FAFB80 ;
    border-radius: 8px;
    padding: 6px;
    gap: 8px;
    border: 1px;
    margin: 0 auto;
    justify-content: space-between;
    margin-bottom: 24px;
}
.authActiveTab{
    width: 148px;
    height: 36px;
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
    padding: 8px, 12px, 8px, 12px;
    border-radius: 6px;
    gap: 8px;
    font-family: Helvetica Neue;
    color:#7F56D9;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

}
.authInactiveTab{
    width: 148px;
    height: 36px;
    padding: 8px, 12px, 8px, 12px !important;
    border-radius: 6px;
    gap: 8px;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    box-shadow: none;
    font-family: Helvetica Neue;
    color:#667085;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
.authPageTitle{
    margin: 0 auto;
    width: 360px;
    height: 40px;
    font-family: Helvetica Neue;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #0E1728;
    margin-bottom: 12px;
}

.authPagesubTitle{
    margin: 0 auto;
    width: 360px;
    height: 20px;
    font-family: Helvetica Neue;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #71819F;
    margin-bottom: 32px;
}
.formLabelStyle{
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #0E1728;
    margin-bottom: 6px;
}
.formInputStyle{
    width: 360px;
    height: 44px;
    padding: 10px, 14px, 10px, 14px;
    border-radius: 8px;
    border: 1px;
    gap: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
}
.forgotPassword{
    width: 360px;
    height: 20px;
    gap: 12px;
    right: 0;
    align-items: right;
    justify-content: right;
    text-align: right;
}
.buttonStyle{
    width: 360px;
    height: 44px;
    border-radius: 8px;
    background-color: #7F56D9;
    color: white;
    padding: 10px, 18px, 10px, 18px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;

}
.googleLoginButton{
    margin: 0 auto;
    width: 360px;
    height: 44px;
    border-radius: 8px;
    padding: 10px, 18px, 10px, 18px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    color: #344054;
    margin-bottom: 32px;
}
.authPageBottom{
    margin: 0 auto;
    width: 360px;
    height: 20px;
    gap: 4px;
    color: #667085;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}

.custom-spinner {
    font-size: 170px !important; /* Increase the font size to make the spinner larger */
    width: 170px !important; /* Set the width to make the spinner larger */
    height: 170px!important;
    margin: 0 auto; /* Center horizontally */
    margin-top: 160px; /* Add top margin for vertical centering */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }