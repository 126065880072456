.header {
  margin: 0.5rem 5rem;
  display: flex;
  justify-content: space-between;
}
.nav-links {
  margin-top: 1rem;
}
.selectedlink{
  text-decoration: underline 0.2rem; /* Adjust the size of the space between the text and the underline */
  text-underline-offset: 0.5rem; 
  text-decoration-skip-ink: none;
  padding: 0rem 1rem;
  color: #667085;
}

.link {
  padding: 0rem 1rem;
  color: #667085;
}
.button {
  padding: 0.5rem 1rem;
  background-color: #7f56d9;
  border-radius: 0.3rem;
  color: white;
}
.button:hover {
  color: white;
}
